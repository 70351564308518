import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import LoadingOverlayView from "../Common/LoadingOverlayView";

class LoginRedirectView extends React.Component {
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = Object.fromEntries(params.entries());

    localStorage.setItem("trackingData", JSON.stringify(paramsObject));
    setTimeout(() => {
      window.location.href = "/auth/google_oauth2";
    }, 1000);
  }

  render() {
    return (
      <Container className="p-t-10 p-b-30">
        <Row>
          <Col sm={24} className="p-t-10">
            <div className="d-flex flex-column align-items-center justify-content-center m-t-50">
              <LoadingOverlayView />
              <p className="ext-center m-t-5">Redirecting to login...</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginRedirectView;
