import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import IndexView from "./components/IndexView";
import SignIn from "./components/SignIn";
import NotFoundView from "./components/NotFoundView";
import LoginRedirectView from "./components/Login/LoginRedirectView";

class App extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState;
  }

  render() {
    return (
      <BrowserRouter>
        <div className="height-100-per">
          <Switch>
            <Route exact path="/" component={IndexView} />
            <Route exact path="/login/redirect" component={LoginRedirectView} />
            <Route exact path="/home/sign_in" component={SignIn} />
            <Route component={NotFoundView} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
