import React from "react";

class LoadingOverlayView extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="loadingOverlay">
        <div className="loadingOverlayContent">
          <img height="40px" src="/images/loading.gif" />
        </div>
      </div>
    );
  }
}
export default LoadingOverlayView;
